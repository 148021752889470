import React, { useEffect, useState } from "react";
import SbEditable from "storyblok-react";
import { blokType, sbLinkType } from "../../types";
import {
  StyledFormStartupWrapper,
  StyledFormStartupSubmit,
  StyledFormMessage
} from "./Partials";
import { INITIAL_FIELDS, headersSelect } from "./Helpers/InitialFields";
import { getOptions } from "./Helpers/FormFields.actions";
import { FormFieldsCustom } from "./FormFieldCustom";

export interface optionSelect {
  component: string;
  label: string;
  value: string;
  defaultValue: string;
  _uid: string | number;
  id: number | string;
}

export interface FormFields {
  _uid: string;
  name: string;
  type: string;
  label: string;
  options: optionSelect[];
  component: string;
  datasource: string;
  _editable: string;
  defaultValue: string;
  DependeField: string;
  Action: string;
  operator: string;
  value: string;
  required: boolean;
  dependecy: Array<any>;
  helptext: string;
  isValid: boolean;
  link: string | sbLinkType;
  subtitle: any;
  maxLength? : number
}

interface IFormCDBlok {
  fields: FormFields[];
  submit: string;
  action: string;
  redirect:  string | sbLinkType ;
}

interface FormCDProps {
  blok: IFormCDBlok & blokType;
}

export const FormStartup: React.FC<FormCDProps> = ({ blok }) => {
  const { fields, submit, action, redirect } = blok;
  const [formFields, setFormFields] = useState<any>([]);
  const { config } = INITIAL_FIELDS;
  const [loaded, setLoaded] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [leadExist, setLead] = useState(false);
  const [leadMessage, setLeadMessage] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const changeOptions = async (
    event: any,
    name: string,
    _uid: string,
    type: string,
    valid: boolean,
    id: number | string
  ) => {
    const {
      target: { value },
    } = event;

    const resetSelectOnchange = ["city", "00NDn00000ajrh7"];

    let dataSource = formFields;

    const indexPattern = dataSource.findIndex(
      ({ options, _uid: _uidC }: FormFields) =>
        options.length
          ? options.some(({ value: valueC }) => valueC === value) &&
          _uidC === _uid
          : _uidC === _uid
    );

    let elementPattern: FormFields = dataSource[indexPattern];

    const indexChildren = elementPattern.options.findIndex(
      ({ value: valueC }) => valueC === value
    );

    type === "radio" &&
      (elementPattern.options = elementPattern.options.map((option) => ({
        ...option,
        defaultValue: "",
      })));

    let elementChildren: optionSelect = elementPattern.options[indexChildren];

    let defaultValue = event.target.hasOwnProperty("checked")
      ? event.target.checked
        ? value
        : ""
      : value;

    elementPattern.defaultValue = defaultValue;
    elementChildren && (elementChildren.defaultValue = defaultValue);
    if (elementPattern.isValid) {
      elementPattern.isValid = valid;
    } else {
      elementPattern = {
        ...elementPattern,
        isValid: valid,
      };
    }

    dataSource[indexPattern] = elementPattern;
    dataSource = dataSource.map((field: FormFields) => {
      let { defaultValue, name: nameC } = field;

      defaultValue =
        name === "country_code"
          ? resetSelectOnchange.some((optionS) => optionS === nameC)
            ? ""
            : defaultValue
          : defaultValue;

      return { ...field, defaultValue };
    });

    const { DependeField } = elementPattern;

    const headers: headersSelect = {
      action: name,
      Location: elementChildren?.value ?? elementPattern.value,
      name: DependeField,
    };

    const fieldsAPI = await getOptions(dataSource, { ...config, headers });
    setFormFields(fieldsAPI);
  };

  const getFieldsAPI = async () => {
    const formFieldsAPI = await getOptions(fields, config);
    setFormFields(formFieldsAPI);
  };

  useEffect(() => {
    getFieldsAPI();
  }, []);

  const validationAnswer = ({
    DependeField,
    operator,
    value: valueM,
    Action,
  }: FormFields) => {
    let resultValidation = true;

    if (
      Boolean(DependeField) &&
      Action !== "call-datasource" &&
      Action !== "conditional-value"
    ) {
      let fieldPattern: FormFields;
      fieldPattern = formFields.find(({ name }: any) => name === DependeField);

      const { defaultValue: dependantValueDefault, options } = fieldPattern;

      resultValidation =
        eval(`'${dependantValueDefault}' ${operator} '${valueM}'`) ||
        (operator === "==" &&
          (options.length
            ? options.some(
              ({ defaultValue }) =>
                defaultValue && valueM.includes(defaultValue)
            )
            : dependantValueDefault && valueM.includes(dependantValueDefault)));
    }

    return resultValidation;
  };

  const checkValidity = (): boolean => {
    let flag = true;
    let aux;
    let checkedFields = [...formFields];

    checkedFields = checkedFields.map((f: FormFields, index: number) => {
      if (f.name == 'UniversityId__c' || f.name == 'city') {
        const input = document.getElementsByName(f.name)[0] as HTMLInputElement | undefined;

        if (input) {
          if (input.value == '') {
            flag = false;
            f = {
              ...f,
              isValid: false
            };
          } else {
            f = {
              ...f,
              isValid: true
            };
          }
        }

      }
      if (f.required && f.type != 'hidden' && f.defaultValue == '') {

        if (f.isValid) {
          f.isValid = false;
        } else {
          aux = f;
          f = {
            ...f,
            isValid: false
          };
          const dependentField = checkedFields.find((field) => field.name === f.DependeField);

          if (dependentField) {
            if (dependentField.defaultValue != '' && f.value != '' && f.operator != '') {
              let resultValidation;

              if (eval(`'${dependentField.defaultValue}' ${f.operator} '${f.value}'`)) {
                resultValidation = true;
              }
              else if (dependentField.defaultValue && f.value.includes(dependentField.defaultValue)) {
                resultValidation = true;
              } else {
                resultValidation = false;
              }
              if (resultValidation) {
                flag = false;
                aux = f;
              }
            } else {
              flag = false;
            }
          } else {
            flag = false;
            aux = f;
          }
        }
      } else if (f.required && f.type != 'hidden' && f.defaultValue != '' && f.isValid == false) {
        flag = false;
      }
      return f;
    })
    setFormFields(checkedFields);
    return flag;
  }

  async function handleSubmit() {

    let validt = checkValidity();
    if (validt) {
      setLoaded(true);
      setButtonDisabled(true);
      setLoaderMessage('Saving your application!');
      let json: any = {}
      formFields.forEach((f: FormFields) => {
        if (f.name == 'UniversityId__c' || f.name == 'city') {
          const input = document.getElementsByName(f.name)[0] as HTMLInputElement | undefined;
          json[f.name] = input?.value;
        } else {
          json[f.name] = f.defaultValue;
        }

      })

      fetch(`${action}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response === 'Lead exists') {
            setLead(true);
            setLeadMessage("It looks like a startup already exists with this email. Please reach out to infohp@hultprize.org to get more information");
            setLoaderMessage("Try again later");
          }
          else if (response == 'Captains email') {
            setLead(true);
            setLeadMessage("Please ensure different emails are used for each participant.");
            setLoaderMessage("Try again");
          }
          else {
            setFormFields([]);
            setFormFields(fields);
            getFieldsAPI();
            setLead(false);
            setLoaderMessage("Application Submitted!");
              // Redirect to link
              if (redirect) {
                const url =
                typeof redirect === "string"
                  ? redirect
                  : redirect?.url
                  ? redirect?.url
                  : `/${redirect?.cached_url}`;

                  if (typeof window !== "undefined" && redirect) {
                    window.location.href = url;
                  }
            }
            
            
          }
          setButtonDisabled(false);
        })
        .catch((err) => {
          setLoaderMessage("Error while trying to save the application. Try again later.");
        });
    }else{
      setLead(true);
      setLeadMessage("There are errors on the form.");     
    }
  }

  return (
    <SbEditable content={blok}>
      <StyledFormStartupWrapper>
        <div>
          {formFields && formFields
            .filter((field: FormFields) => validationAnswer(field))
            .map((field: FormFields, index: number) => {
              return (
                <FormFieldsCustom
                  key={`form-fields-custom-${field._uid}-${index}`}
                  field={field}
                  formFields={formFields}
                  index={index - 1}
                  changeOptions={changeOptions}
                />
              );
            })}
        </div>

        <StyledFormMessage>
          {leadExist && leadMessage}
        </StyledFormMessage>

        <StyledFormStartupSubmit disabled={isButtonDisabled} onClick={handleSubmit} >
          {loaded ? loaderMessage : submit}
        </StyledFormStartupSubmit>

      </StyledFormStartupWrapper>
    </SbEditable >
  );
};