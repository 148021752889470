import React, { useEffect, useState } from "react";
import SbEditable from "storyblok-react";
import { blokType, sbLinkType } from "../../types";
import { StyledFormCDWrapper, StyledFormCDWrapperSubmit } from "./Partials/";
import { INITIAL_FIELDS, headersSelect } from "./Helpers/InitialFields";
import { getOptions } from "./Helpers/FormFields.actions";
import { FormFieldsCustom } from "./FormFieldCustom";

export interface optionSelect {
  component: string;
  label: string;
  value: string;
  defaultValue: string;
  _uid: string | number;
  id: number | string;
}

export interface FormFields {
  _uid: string;
  name: string;
  type: string;
  label: string;
  options: optionSelect[];
  component: string;
  datasource: string;
  _editable: string;
  defaultValue: string;
  DependeField: string;
  Action: string;
  operator: string;
  value: string;
  required: boolean;
  dependecy: Array<any>;
  helptext: string;
  isValid: boolean;
  link: string | sbLinkType;
  subtitle: any;
  maxLength?: number;
}

interface IFormCDBlok {
  fields: FormFields[];
  submit: string;
  action: string;
}

interface FormCDProps {
  blok: IFormCDBlok & blokType;
}

export const FormCD: React.FC<FormCDProps> = ({ blok }) => {
  const { fields, submit, action } = blok;
  const [formFields, setFormFields] = useState<any>([]);
  const { config } = INITIAL_FIELDS;

  const changeOptions = async (
    event: any,
    name: string,
    _uid: string,
    type: string,
    valid: boolean,
    id: number | string
  ) => {
    const {
      target: { value },
    } = event;

    const resetSelectOnchange = ["city", "00NDn00000ajrh7"];

    let dataSource = formFields;

    const indexPattern = dataSource.findIndex(
      ({ options, _uid: _uidC }: FormFields) =>
        options.length
          ? options.some(({ value: valueC }) => valueC === value) &&
            _uidC === _uid
          : _uidC === _uid
    );

    let elementPattern: FormFields = dataSource[indexPattern];

    const indexChildren = elementPattern.options.findIndex(
      ({ value: valueC }) => valueC === value
    );

    type === "radio" &&
      (elementPattern.options = elementPattern.options.map((option) => ({
        ...option,
        defaultValue: "",
      })));

    let elementChildren: optionSelect = elementPattern.options[indexChildren];

    let defaultValue = event.target.hasOwnProperty("checked")
      ? event.target.checked
        ? value
        : ""
      : value;

    elementPattern.defaultValue = defaultValue;
    elementChildren && (elementChildren.defaultValue = defaultValue);
    if (elementPattern.isValid) {
      elementPattern.isValid = valid;
    } else {
      elementPattern = {
        ...elementPattern,
        isValid: valid,
      };
    }

    dataSource[indexPattern] = elementPattern;
    dataSource = dataSource.map((field: FormFields) => {
      let { defaultValue, name: nameC } = field;

      defaultValue =
        name === "country_code"
          ? resetSelectOnchange.some((optionS) => optionS === nameC)
            ? ""
            : defaultValue
          : defaultValue;

      return { ...field, defaultValue };
    });

    const { DependeField } = elementPattern;

    const headers: headersSelect = {
      action: name,
      Location: elementChildren?.value ?? elementPattern.value,
      name: DependeField,
    };

    const fieldsAPI = await getOptions(dataSource, { ...config, headers });

    setFormFields(fieldsAPI);
  };

  const getFieldsAPI = async () => {
    const formFieldsAPI = await getOptions(fields, config);
    setFormFields(formFieldsAPI);
  };

  useEffect(() => {
    getFieldsAPI();
  }, []);

  const validationAnswer = ({
    DependeField,
    operator,
    value: valueM,
    Action,
  }: FormFields) => {
    let resultValidation = true;

    if (
      Boolean(DependeField) &&
      Action !== "call-datasource" &&
      Action !== "conditional-value"
    ) {
      let fieldPattern: FormFields;
      fieldPattern = formFields.find(({ name }: any) => name === DependeField);

      const { defaultValue: dependantValueDefault, options } = fieldPattern;

      resultValidation =
        eval(`'${dependantValueDefault}' ${operator} '${valueM}'`) ||
        (operator === "==" &&
          (options.length
            ? options.some(
                ({ defaultValue }) =>
                  defaultValue && valueM.includes(defaultValue)
              )
            : dependantValueDefault && valueM.includes(dependantValueDefault)));
    }

    return resultValidation;
  };

  const checkValidity = () => {
    let checkedFields = [...formFields];

    checkedFields = checkedFields.map((f: FormFields) => {
      if (f.required && f.type != "hidden" && f.defaultValue == "") {
        if (f.isValid) {
          f.isValid = false;
        } else {
          f = {
            ...f,
            isValid: false,
          };
        }
      }
      if (f.Action == "conditional-value") {
        f.isValid = false;
        f.defaultValue = "";
        let resultValidationdos = true;

        let {
          defaultValue: dependantValueDefault,
          options,
          operator,
          value: valueM,
        } = f;

        resultValidationdos =
          eval(`'${dependantValueDefault}' ${operator} '${valueM}'`) ||
          (operator === "==" &&
            (options.length
              ? options.some(({ defaultValue }) => {
                  const includesDefaultValue =
                    defaultValue && valueM.includes(defaultValue);
                  return includesDefaultValue;
                })
              : dependantValueDefault &&
                valueM.includes(dependantValueDefault)));

        if (resultValidationdos) {
          f.options = f.options.map((option) => {
            if (option.defaultValue && valueM.includes(option.defaultValue)) {
              return {
                ...option,
                defaultValue: "",
              };
            }
            return option;
          });
        }
      }
      return f;
    });
    setFormFields(checkedFields);
  };

  return (
    <SbEditable content={blok}>
      <StyledFormCDWrapper action={action} method="POST">
        <div>
          {formFields &&
            formFields
              .filter((field: FormFields) => validationAnswer(field))
              .map((field: FormFields, index: number) => {
                return (
                  <FormFieldsCustom
                    key={`form-fields-custom-${field._uid}-${index}`}
                    field={field}
                    formFields={formFields}
                    index={index - 1}
                    changeOptions={changeOptions}
                  />
                );
              })}
        </div>
        <StyledFormCDWrapperSubmit onClick={checkValidity} type="submit">
          {submit}
        </StyledFormCDWrapperSubmit>
      </StyledFormCDWrapper>
    </SbEditable>
  );
};